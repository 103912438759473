<template>
  <div class="keyboard-container">
    <div class="keyboard-row diacritic-keys">
      <button v-for="(diacritic, index) in diacriticKeys" :key="index" @click="addDiacritic(diacritic)">
        {{ diacritic }}
      </button>
    </div>
    <div class="keyboard-row letter-keys">
      <button v-for="(key, index) in firstRowLetters" :key="index" @click="addLetter(key)">
        {{ key }}
      </button>
    </div>
    <div class="keyboard-row letter-keys">
      <button v-for="(key, index) in secondRowLetters" :key="index" @click="addLetter(key)">
        {{ key }}
      </button>
    </div>
    <div class="keyboard-row special-keys">
      <button v-for="key in specialKeys" :key="key" @click="addLetter(key)">{{ key }}</button>
    </div>
    <div class="keyboard-row">
      <button class="delete-btn" @click="deleteLetter">⌫</button>
      <button class="space-bar" @click="addLetter(' ')">_</button>
      <button class="clear-btn" @click="clearAll">X</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      diacriticKeys: ['َ', 'ِ', 'ُ', 'ً', 'ٍ', 'ٌ', 'ّ', 'ْ', 'ٰ', 'ٖ', 'ٗ'],
      firstRowLetters: ['ظ', 'ط', 'ض', 'ص', 'ش', 'س', 'ز', 'ر', 'ذ', 'د', 'خ', 'ح', 'ج', 'ث', 'ت', 'ب', 'ا'],
      secondRowLetters: ['ى', 'ة', 'ي', 'ء', 'ه', 'و', 'ن', 'م', 'ل', 'ك', 'ق', 'ف', 'غ', 'ع'],
      specialKeys: ['ى', 'ئ', 'إ', 'ؤ', 'أ', 'آ', 'ـ']
    };
  },
  methods: {
    addLetter(key) {
      this.$emit('letter-pressed', key);
    },
    addDiacritic(diacritic) {
      this.$emit('diacritic-pressed', diacritic);
    },
    deleteLetter() {
      this.$emit('delete-pressed');
    },
    clearAll() {
      this.$emit('clear-all');
      console.log('Clear all event emitted'); // Tambahkan ini untuk debugging
    }
  }
};
</script>

<style scoped>
.keyboard-container {
  width: 100%;
  max-width: 1000px;
  height: 50vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  flex: 1;
}

button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 2.5vw, 1.2rem);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 0;
  margin: 0;
}

button:hover {
  background-color: #e0e0e0;
}

.diacritic-keys button {
  font-size: clamp(1.2rem, 3.5vw, 14rem);
}

.letter-keys button, .special-keys button {
  flex-basis: calc(100% / 17);
  font-size: clamp(1.2rem, 3.5vw, 2rem);
}

.delete-btn {
  flex: 2;
}

.space-bar {
  flex: 5;
}

.clear-btn {
  flex: 2;
  font-size: clamp(0.6rem, 2vw, 1rem);
}

@media (max-width: 768px) {
  .keyboard-container {
    gap: 0.25rem;
    padding: 0.25rem;
  }

  .keyboard-row {
    gap: 0.125rem;
  }

  button {
    font-size: clamp(0.6rem, 2vw, 1rem);
  }

  .diacritic-keys button {
    font-size: clamp(0.8rem, 3vw, 1.5rem);
  }
}
</style>