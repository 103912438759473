<template>
  <div id="app">
    <h1>Belajar Huruf Sambung</h1>
    <div class="text-display" :style="{ fontSize: fontSize + 'px' }">
      <span
        v-for="(char, index) in connectedText"
        :key="index"
        @click="selectChar(index)"
        :class="{ 'selected': selectedIndex === index }"
      >
        {{ char }}
      </span>
      <span v-if="textArray.length === 0" class="placeholder">Tidak ada huruf yang diketik</span>
    </div>
    
    <!-- Slider untuk mengatur ukuran huruf -->
    <div class="font-slider">
      <label for="fontSize">Ukuran Huruf </label>
      <input type="range" id="fontSize" v-model="fontSize" min="24" max="72" />
    </div>

    <ArabicKeyboard 
      @letter-pressed="addLetter" 
      @diacritic-pressed="addDiacritic"
      @delete-pressed="deleteLetter"
      @clear-all="clearAll"
    />
  </div>
</template>

<script>
import ArabicKeyboard from './components/ArabicKeyboard.vue';

const isolatedToConnected = {
  'ا': ['ﺍ', 'ﺍ', 'ﺎ', 'ﺍ'],
  'ب': ['ﺏ', 'ﺑ', 'ﺒ', 'ﺐ'],
  'ت': ['ﺕ', 'ﺗ', 'ﺘ', 'ﺖ'],
  'ث': ['ﺙ', 'ﺛ', 'ﺜ', 'ﺚ'],
  'ج': ['ﺝ', 'ﺟ', 'ﺠ', 'ﺞ'],
  'ح': ['ﺡ', 'ﺣ', 'ﺤ', 'ﺢ'],
  'خ': ['ﺥ', 'ﺧ', 'ﺨ', 'ﺦ'],
  'د': ['ﺩ', 'ﺩ', 'ﺪ', 'ﺪ'],
  'ذ': ['ﺫ', 'ﺫ', 'ﺬ', 'ﺬ'],
  'ر': ['ﺭ', 'ﺭ', 'ﺮ', 'ﺮ'],
  'ز': ['ﺯ', 'ﺯ', 'ﺰ', 'ﺰ'],
  'س': ['ﺱ', 'ﺳ', 'ﺴ', 'ﺲ'],
  'ش': ['ﺵ', 'ﺷ', 'ﺸ', 'ﺶ'],
  'ص': ['ﺹ', 'ﺻ', 'ﺼ', 'ﺺ'],
  'ض': ['ﺽ', 'ﺿ', 'ﻀ', 'ﺾ'],
  'ط': ['ﻁ', 'ﻃ', 'ﻄ', 'ﻂ'],
  'ظ': ['ﻅ', 'ﻇ', 'ﻈ', 'ﻆ'],
  'ع': ['ﻉ', 'ﻋ', 'ﻌ', 'ﻊ'],
  'غ': ['ﻍ', 'ﻏ', 'ﻐ', 'ﻎ'],
  'ف': ['ﻑ', 'ﻓ', 'ﻔ', 'ﻒ'],
  'ق': ['ﻕ', 'ﻗ', 'ﻘ', 'ﻖ'],
  'ك': ['ﻙ', 'ﻛ', 'ﻜ', 'ﻚ'],
  'ل': ['ﻝ', 'ﻟ', 'ﻠ', 'ﻞ'],
  'م': ['ﻡ', 'ﻣ', 'ﻤ', 'ﻢ'],
  'ن': ['ﻥ', 'ﻧ', 'ﻨ', 'ﻦ'],
  'ه': ['ﻩ', 'ﻫ', 'ﻬ', 'ﻪ'],
  'و': ['ﻭ', 'ﻭ', 'ﻮ', 'ﻮ'],
  'ي': ['ﻱ', 'ﻳ', 'ﻴ', 'ﻲ'],
};

const nonConnectingChars = ['ا', 'د', 'ذ', 'ر', 'ز', 'و'];

export default {
  components: {
    ArabicKeyboard
  },
  data() {
    return {
      textArray: [],
      selectedIndex: null,
      fontSize: 48 // Ukuran font default
    };
  },
  computed: {
    connectedText() {
      return this.textArray.map((char, index) => {
        const connectedChar = this.getConnectedForm(char.letter, index);
        const orderedDiacritics = this.orderDiacritics(char.diacritics);
        return connectedChar + orderedDiacritics;
      });
    }
  },
  methods: {
    addLetter(letter) {
      // Jika huruf yang dimasukkan adalah spasi
      if (letter === ' ') {
        // Tambahkan spasi tanpa mengubah huruf sebelumnya
        this.textArray.push({ letter: ' ', diacritics: [] });
        this.selectedIndex = this.textArray.length - 1; // Set indeks yang dipilih ke spasi
      } else {
        // Tambahkan huruf biasa
        this.textArray.push({ letter: letter, diacritics: [] });
        this.selectedIndex = this.textArray.length - 1; // Set indeks yang dipilih ke huruf yang baru ditambahkan
      }
    },
    addDiacritic(diacritic) {
      if (this.selectedIndex !== null) {
        let newDiacritics = [];

        if (diacritic === 'ْ') { // Sukun
          newDiacritics = [diacritic];
        } else if (diacritic === 'ّ') { // Tasydid
          const existingDiacritics = this.textArray[this.selectedIndex].diacritics;
          // Tasydid bisa digabungkan dengan diakritik lain kecuali sukun
          if (!existingDiacritics.includes('ّ') && !existingDiacritics.includes('ْ')) {
            // Filter hanya satu harakat
            newDiacritics = existingDiacritics.filter(d => ['َ', 'ِ', 'ُ', 'ٰ', 'ٖ', 'ٗ'].includes(d));
            newDiacritics.push('ّ');
          } else {
            newDiacritics = existingDiacritics;
          }
        } else if (['َ', 'ِ', 'ُ', 'ٰ', 'ٖ', 'ٗ'].includes(diacritic)) { // Fathah, Kasrah, Dhammah, serta diakritik lainnya
          const existingDiacritics = this.textArray[this.selectedIndex].diacritics;
          if (!existingDiacritics.includes('ْ')) {
            // Hanya boleh satu harakat di antara 'َ', 'ِ', 'ُ'
            newDiacritics = existingDiacritics.filter(d => d === 'ّ' || !['َ', 'ِ', 'ُ', 'ٰ', 'ٖ', 'ٗ'].includes(d));
            newDiacritics.push(diacritic);
          }
        } else { // Harakat lainnya (tanwin, dsb.)
          newDiacritics = [diacritic];
        }

        this.textArray[this.selectedIndex].diacritics = newDiacritics;
        this.selectedIndex = null;
      }
    },
    selectChar(index) {
      this.selectedIndex = index;
    },
    getConnectedForm(char, index) {
      if (!isolatedToConnected[char]) {
        return char;
      }

      const prevChar = index > 0 ? this.textArray[index - 1].letter : null;
      const nextChar = index < this.textArray.length - 1 ? this.textArray[index + 1].letter : null;

      // Memastikan huruf tidak tersambung jika sebelum atau setelahnya adalah spasi
      const canConnectToPrev = prevChar && !nonConnectingChars.includes(prevChar) && prevChar !== ' ';
      const canConnectToNext = nextChar && !nonConnectingChars.includes(char) && nextChar !== ' ';

      if (canConnectToPrev && canConnectToNext) {
        return isolatedToConnected[char][2]; // Middle form
      } else if (canConnectToNext) {
        return isolatedToConnected[char][1]; // Initial form
      } else if (canConnectToPrev) {
        return isolatedToConnected[char][3]; // Final form
      } else {
        return isolatedToConnected[char][0]; // Isolated form
      }
    },
    orderDiacritics(diacritics) {
      const order = ['ْ', 'ّ', 'ً', 'ٌ', 'ٍ', 'َ', 'ُ', 'ِ'];
      return diacritics.sort((a, b) => order.indexOf(a) - order.indexOf(b)).join('');
    },
    deleteLetter() {
      if (this.textArray.length > 0) {
        this.textArray.pop();
        this.selectedIndex = this.textArray.length - 1;
      }
    },
    clearAll() {
      this.textArray = []; // Menghapus semua huruf
      this.selectedIndex = null; // Mengatur ulang indeks yang dipilih
    }
  }
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Memastikan tinggi maksimal dari web adalah 100% dari layar */
  text-align: center;
}

.text-display {
  padding: 20px;
  margin-bottom: 20px;
  direction: rtl;
  min-height: 40%; /* Tinggi text display diatur menjadi 50% dari tinggi layar */
  border: 1px solid #ccc;
  display: flex;
  justify-content: center; /* Mengatur alignment huruf ke tengah */
  align-items: center; /* Mengatur alignment vertikal ke tengah */
  flex-wrap: wrap; /* Memungkinkan huruf berpindah baris jika diperlukan */
}

.text-display span {
  cursor: pointer;
  padding: 2px;
  transition: background-color 0.3s ease;
}

.text-display span.selected {
  background-color: #e0e0e0;
}

.placeholder {
  font-size: 24px;
  color: #888;
}

.font-slider {
  margin: 20px 0;
}
</style>
